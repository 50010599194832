.profile-card {
  position: relative;
  margin: 10px;
  cursor: pointer;
  height: 10vw;
  max-height: 200px;
  max-width: 200px;
  min-height: 84px;
  min-width: 84px;
  text-decoration: none;
  width: 10vw;
  border-radius: 5rem;
}

.profile-card:hover {
  border-color: #e5e5e5;
  color: #e5e5e5;
}

.profile-name {
  color: #e5e5e5;
  text-transform: capitalize;
}

.image-container {
  position: relative;
  width: 100%;
  height: 85%;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0.2em solid transparent;
}

.image-container:hover {
  border-color: #e5e5e5;
  color: #e5e5e5;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-name {
  color: white;
  display: block;
  font-size: 1.3vw;
  line-height: 1.2em;
  margin: 0.6em 0;
  min-height: 1.8em;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-weight: 400;
}

@media (max-width: 768px) {
  .profile-card {
    flex-wrap: wrap;
    width: 40vw;
    height: 40vw;
    cursor: pointer;
  }

  .profile-name {
    font-size: 14px
  }
}