.music-page {
  text-align: center;
  padding: 100px 20px;
  color: #fff;
  font-family: Arial, sans-serif;
}

.music-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #f5f5f5;
}

.quote {
  font-style: italic;
  color: #ccc;
  margin: 20px 0 30px;
  font-size: 1.2rem;
  animation: fadeIn 2s ease-in-out;
}

.genre-section, .albums-section {
  margin: 30px 0;
}

.genres, .albums {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.genre-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px 25px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  font-size: 1.1rem;
  cursor: pointer;
}

.genre-card:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.2);
}

.album-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  width: 200px;
  transition: transform 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.4);
}

.album-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 10px 20px rgba(255, 0, 0, 0.5);
}

.album-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.album-details {
  padding: 10px;
}

.album-details h4 {
  margin: 10px 0 5px;
  font-size: 1.1rem;
  color: #fff;
}

.album-details p {
  margin: 0;
  font-size: 0.9rem;
  color: #ccc;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.genre-card {
  animation: popIn 0.5s forwards ease-in;
}

@keyframes popIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}
