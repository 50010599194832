.profile-page {
  display: flex;
  align-items: center;
  height: 75vh;
  color: #fff;
  background-size: cover;
  background-position: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .profile-page {
    height: 100vh;
    padding-bottom: 5rem;
  }
}
