.netflix-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #141414;
  overflow: hidden;
}

.netflix-logo {
  width: 500px;
  height: auto;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeIn 0.5s forwards ease-in;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate.netflix-logo {
  animation: zoomOut 3s forwards ease-in-out;
}

@keyframes zoomOut {
  0%, 80% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
