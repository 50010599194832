.continue-watching-row {
  padding: 20px;
  color: #fff;
}

.continue-watching-row:hover {
 cursor: pointer;
}

.row-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-row {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  scrollbar-width: none; /* For Firefox */
}

.card-row::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.pick-card {
  position: relative;
  min-width: 150px;
  flex: 0 0 auto;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.pick-card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.pick-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Blackish overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.pick-card:hover .overlay {
  opacity: 1;
}

.pick-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}