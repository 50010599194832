.projects-container {
  padding: 120px 20px;
  background-color: #141414;
  color: #fff;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.project-card {
  background: #1e1e1e;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(160, 16, 16, 0.6); /* Base shadow */
  opacity: 0;
  animation: fadeInUp 0.5s forwards ease-in-out;
  animation-delay: var(--delay);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(244, 0, 13, 0.6); /* Netflix shadow */
  cursor: pointer;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 2px solid #e50914;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-details {
  padding: 20px;
}

.project-details h3 {
  font-size: 1.2rem;
  color: #e74e56;
  margin-bottom: 10px;
  color: #e50914;
}

.project-details p {
  font-size: 0.8rem;
  margin-bottom: 15px;
  line-height: 1.2rem;
}

.tech-used {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.tech-badge {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 12px;
  background: #2c2c2c;
  color: #fff;
  font-size: 0.8rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.tech-badge:hover {
  transform: scale(1.1);
  background-color: #e50914;
  color: #fff;
  box-shadow: 0 4px 10px rgba(229, 9, 20, 0.6); /* Netflix shadow */
}

.tech-badge svg {
  margin-right: 5px;
  font-size: 1.2rem;
}
