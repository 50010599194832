.skills-container {
  padding: 40px;
  text-align: center;
  color: #fff;
  max-width: 1000px;
  margin: auto;
}

.skills-title {
  font-size: 2.5rem;
  color: #e50914; /* Netflix red */
  margin-bottom: 30px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.skill-card {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  color: #e6e6e6;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.skill-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(255, 0, 0, 0.5); /* Netflix red glow on hover */
  cursor: pointer;
}

.icon {
  font-size: 2rem;
  color: #e50914; /* Netflix red */
  margin-bottom: 10px;
}

.skill-name {
  display: flex;
  justify-content: center;
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(-20px);
  animation: dropIn 0.5s forwards ease-out;
}

@keyframes dropIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.skill-description {
  font-size: 0.9rem;
  color: #999;
}


.skills-title {
  font-size: 2.5rem;
  color: #da8185; /* Netflix red */
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeSlideIn 1s ease forwards;
}

/* Category Animation */
.category-title {
  font-size: 1.8rem;
  color: #e6e6e6;
  margin: 30px 0 20px;
  border-bottom: 2px solid #e50914;
  padding-bottom: 5px;
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeSlideIn 0.8s ease forwards;
}

.category-title:nth-child(1) {
  animation-delay: 0.2s;
}
.category-title:nth-child(2) {
  animation-delay: 0.4s;
}
.category-title:nth-child(3) {
  animation-delay: 0.6s;
}
.category-title:nth-child(4) {
  animation-delay: 0.8s;
}
.category-title:nth-child(5) {
  animation-delay: 1s;
}

/* Keyframe for Slide and Fade-in Animation */
@keyframes fadeSlideIn {
  to {
    opacity: 1;
    transform: translateY(10);
  }
}
