.vertical-timeline-element-content {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vertical-timeline-element-content:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}


.timeline-container {
  text-align: center;
  padding: 20px;
  margin-top: 5rem;
}

.timeline-title {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 40px;
  display: inline-block;
  text-align: center;
  opacity: 0; /* Start invisible */
  transform: translateY(20px); /* Start slightly lower */
  animation: fadeInUp 2s ease-in-out both; /* Animation properties */
}

/* Define the keyframes for the fade-in-up animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
