.profile-banner {
  display: flex;
  align-items: flex-end;
  justify-content: left;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 40px 20px 20px 20px;
}

.profile-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.banner-content {
  position: relative;
  max-width: 1000px;
  font-weight: bold;
  width: 100%;
  padding: 50px 0px;
  box-sizing: border-box;
  padding-top: 10px;
  font-weight: bold;
}

.banner-headline {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.banner-description {
  font-size: 1rem;
  margin-bottom: 20px;
  font-size: 14px;
}

.banner-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.banner-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.play-button {
  background-color: #e50914;
}

.info-button {
  background-color: rgba(109, 109, 110, 0.7);
}

.banner-button:hover {
  background-color: #b81d24;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner-content {
    max-width: 100%;
  }
  .profile-banner {
    padding: 60px 20px;
    align-items: center;
    justify-content: center;
  }

  .banner-headline {
    font-size: 2rem;
    margin-top: 10rem;
  }

  .banner-description {
    line-height: 1.5;
    font-size: 12px;
  }

  /* Button adjustments */
  .banner-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .banner-button {
    width: auto;
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

