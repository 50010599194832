/* Ensure the container takes up the full viewport height and centers the content */
.timeline-container {
  display: flex;
  justify-content: center;
}

/* Styling for the recommendation card */
.recommendation-card {
  max-width: 800px;
  width: 100%; /* Responsive width to ensure it fits smaller screens */
  background-color: #1e1e1e; /* Slightly lighter for contrast */
  color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(255, 0, 0, 0.3), 0 0 10px rgba(255, 0, 0, 0.2); /* Softer shadow with subtle glow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px dotted #e50914; /* Netflix red border */
}

.recommendation-card:hover {
  transform: scale(1.03); /* Slight zoom on hover */
  box-shadow: 0 12px 30px rgba(255, 0, 0, 0.5), 0 0 15px rgba(255, 0, 0, 0.4); /* Enhanced shadow on hover */
}

.recommendation-header {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #e50914; /* Netflix red border */
}

.recommendation-header h3 {
  font-size: 1.5rem;
  color: #e50914; /* Netflix red for name */
  margin: 0;
}

.recommendation-header p {
  margin: 3px 0;
  font-size: 0.9rem;
  color: #999;
}

.date {
  color: #ccc;
  font-size: 0.8rem;
}

.recommendation-body {
  font-size: 1rem;
  line-height: 1.6;
}

.recommendation-body p {
  margin-bottom: 15px;
}

.recommendation-body strong {
  color: #e50914; /* Highlighted text in Netflix red */
}

.recommendation-body p:first-of-type {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 20px;
}
