.top-picks-row {
  margin: 20px 0;
  padding: 10px 20px
}

.row-title {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

.card-row {
  display: flex;
  gap: 15px;
}

.pick-card {
  position: relative;
  width: 250px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: slideIn 0.5s ease forwards;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pick-card:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0 8px 20px rgba(255, 0, 0, 0.5); /* Netflix red glow on hover */
}

.pick-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.pick-card:hover .pick-image {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pick-card:hover .overlay {
  opacity: 1;
}

.pick-label {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.pick-label:hover {
 color: rgb(153, 80, 80);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}