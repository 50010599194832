.work-permit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.work-permit-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  padding: 25px 35px;
  max-width: 500px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.5s ease-out;
  color: #333;
}

.work-permit-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.work-permit-headline {
  font-size: 2rem;
  color: #e50914;
  margin-bottom: 20px;
  font-weight: bold;
}

.work-permit-summary {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.work-permit-summary strong {
  color: #e50914;
}

.additional-info {
  font-size: 1rem;
  color: #333;
  margin-top: 20px;
  font-style: italic;
}
