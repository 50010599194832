/* Navbar.css */

/* Main Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  background-image: linear-gradient(180deg, rgb(0 0 1 / 90%) 10%, transparent 100%);
  padding: 20px;
  z-index: 3;
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  background-color: #141414; /* Netflix black when scrolled */
}

.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 30px;
  margin-right: 30px;
  margin-top: 5px;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.navbar-links li a:hover {
  color: #e50914; /* Netflix red */
}

/* Profile Icon */
.profile-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: transform 0.3s;
  margin-right: 2rem;
  cursor: pointer;
}

/* Hide the hamburger and sidebar in desktop view */
.hamburger,
.sidebar-overlay,
.sidebar {
  display: none;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .navbar-logo img {
    height: 20px;
    margin-right: auto;
  }
  .profile-icon {
    width: 24px;
    height: 24px;
  }


  /* Hide Navbar Links in Mobile */
  .navbar-links {
    display: none;
  }

  /* Show Hamburger Menu */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
    transition: 0.3s;
  }

  /* Sidebar Overlay */
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: none;
  }

  .sidebar-overlay.open {
    display: block;
  }

  /* Sidebar Styling */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    background-color: #141414;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 4;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-logo img {
    height: 30px;
    margin-bottom: 20px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .sidebar ul li {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .sidebar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  /* Close button inside the sidebar */
  .hamburger-icon {
    font-size: 1.5rem;
    margin-right: 5px;
  }

  /* Sidebar adjustments */
  .sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
