.more-info-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(109, 109, 110, 0.7); /* Netflix secondary color */
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .label {
    font-size: 1rem;
    color: white;
  }
}

.more-info-button:hover {
  background-color: rgba(109, 109, 110, 0.9);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spacer {
  width: 1rem;
}
